.root {
  position: relative;
  display: flex;
}

.icon {
  position: absolute;
  z-index: 1;
  top: 8px;
  left: 12px;
  font-size: 20px;
}

.input {
  width: 100%;
  padding: 9px 16px 9px 43px;
  border: 1px solid #cacdd9;
  background-color: #fff;
  border-radius: 4px;
  color: #54586c;
  font-size: 14px;
  line-height: 16px;

  &:disabled {
    background-color: #e9ecef;
  }

  &IsInvalid {
    border-color: #f1504c;
  }
}

.rootLarge {
  .icon {
    top: 12px;
    left: 16px;
    font-size: 24px;
  }

  .input {
    padding: 14px 16px 14px 54px;
    border: 1px solid #cacdd9;
    background: #fff;
    border-radius: 8px;
    font-size: 16px;
    line-height: 16px;
  }
}
