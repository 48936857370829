.root {
  display: block;
  overflow: hidden;
  flex-shrink: 0;
  padding: 8px 20px;
  border-right: 1px solid #edeff7;
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sticky {
  position: sticky;
  z-index: 45;
}

.editable {
  cursor: pointer;
}
