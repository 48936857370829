.drop {
  padding: 20px;
  border: 2px dashed #d2d2db;
  background-color: #fff;
  border-radius: 4px;
  text-align: center;

  img {
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0 6px 20px rgba(3, 5, 10, 0.1);
    cursor: pointer;
  }
}

.files {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    position: relative;
    padding: 8px 20px 8px 10px;
    border: 1px solid #d2d2db;
    border-radius: 4px;
    font-size: 12px;

    img {
      margin-right: 6px;
    }

    svg {
      position: absolute;
      top: 6px;
      right: 6px;
    }
  }
}
