.root {
  position: absolute;
  z-index: 60;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.25);
}
