.root {
  position: relative;
}

.input {
  overflow: hidden;
  width: 100%;
  border: 1px solid #d2d2db;
  background-color: #fff;
  border-radius: 4px;
  color: #54586c;
  font-size: inherit;
  line-height: 16px;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;

  &:disabled {
    background-color: #e9ecef;
  }

  &Size {
    &Small {
      height: 24px;
      padding: 4px 30px 4px 12px;
    }

    &Normal {
      height: 36px;
      padding: 10px 30px 10px 12px;
    }

    &Large {
      height: 50px;
      padding: 17px 30px 17px 12px;
    }
  }

  &Borderless {
    padding-right: 18px;
    padding-left: 0;
    border: none;
    background-color: transparent;

    ~ .toggleButton {
      padding: 0;
    }

    &:disabled {
      background-color: transparent;
    }
  }

  &Invalid {
    border-color: #f1504c;
  }

  &Focused {
    border-color: #ff7a00;
  }
}

.toggleButton {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 12px;
  border: none;
  background: transparent;
}
