.modal-content {
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
  align-items: center;
  padding: 30px 30px 15px;
  border: none;
}

.modal-title {
  margin-right: 12px;
  color: #54586c;
  font-size: 24px;
  font-weight: 500;
}

.modal-body {
  padding: 15px 30px;
}

.modal-footer {
  padding: 15px 30px 30px;
  border: none;
}
