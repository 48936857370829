.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  border-bottom: 1px solid #edeff7;
  grid-area: header;
}

.right {
  display: flex;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 50%;
}

.user {
  display: block;
  font-size: 18px;
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .root {
    &DrawerOpened {
      justify-content: flex-end;

      .right {
        display: none;
      }
    }
  }
}
