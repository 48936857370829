.root {
  overflow: hidden;
  flex-shrink: 0;
  padding: 13px 20px;
  border-right: 1px solid #edeff7;
  font-size: 16px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;

  & > svg {
    margin-bottom: 2px;
    margin-left: 4px;
  }
}

.sticky {
  position: sticky;
  z-index: 45;
}

.sortable {
  cursor: pointer;
}
