.root {
  position: sticky;
  z-index: 45;
  right: 0;
  flex: 1;

  & > span {
    display: flex;
    width: 40px;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }
}
