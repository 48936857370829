.custom-switch {
  label {
    line-height: 22px;
  }

  & > .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #66af37;
    background-color: #66af37;
  }

  & > .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(102, 175, 55, 0.5);
  }
}

.custom-radio {
  height: 24px;
  padding-left: 36px;

  .custom-control-input:checked + .custom-control-label {
    font-weight: 500;
  }

  .custom-control-label {
    font-family: $font-family-sans-serif;
    font-weight: 400;
    line-height: 24px;

    &::before {
      top: 2px;
      left: -32px;
      width: 20px;
      height: 20px;
    }

    &::after {
      top: 6px;
      left: -28px;
      background: no-repeat;
    }
  }
}

.custom-checkbox {
  .custom-control-input:checked + .custom-control-label {
    font-weight: 500;

    &::before {
      border-color: #adb5bd;
      background-color: transparent;
    }

    &::after {
      top: 6px;
      left: -21px;
      width: 10px;
      height: 10px;
      background: linear-gradient(0deg, #ff7a00, #ff7a00), linear-gradient(0deg, #ff7a00, #ff7a00),
        #ff7a00;
      border-radius: 2px;
    }
  }

  .custom-control-label {
    font-family: $font-family-sans-serif;
    font-weight: 400;
  }
}
