@import 'react-phone-input-2/lib/high-res.css';

.react-tel-input {
  font-size: 14px;

  .special-label {
    display: none;
  }

  .form-control {
    width: 100%;
  }

  .flag-dropdown.is-invalid {
    border-color: #f1504c;
  }

  .country-list {
    border: 1px solid #d2d2db;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.06);

    .country {
      &.highlight {
        background-color: #ff7a00;

        &:hover {
          background-color: #ff7a00;
        }

        color: #fff;

        .dial-code {
          color: #fff;
        }
      }

      &:hover {
        background-color: rgba(255, 122, 0, 0.25);
      }
    }
  }
}
