.form-group {
  .form-control {
    min-height: 36px;
  }

  .btn-link-light {
    padding: 0;
    font-family: $font-family-sans-serif;
    font-size: 12px;
    font-weight: 400;
  }
}

.form-label,
.custom-control-label {
  font-family: 'Source Sans Pro', $font-family-sans-serif;
  font-weight: 600;

  button {
    min-width: 20px;
    height: 18px;
    padding: 0 3px;
    margin-left: 4px;
    border-radius: 4px;
    font-family: $font-family-sans-serif;
    font-size: 12px;
    font-weight: 400;
  }

  & ~ .btn-link-light {
    padding: 0;
    font-family: $font-family-sans-serif;
    font-size: 12px;
    font-weight: 400;
  }
}
