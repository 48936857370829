@import 'styles/variables';

.trigger {
  display: inline-block;
  color: $color-icon;
  cursor: pointer;

  &:hover {
    color: $color-primary;

    & > svg {
      color: $color-primary;
    }
  }
}

.popper {
  z-index: 55;
  width: 180px;
  padding: 8px 0;
  background-color: #656a81 !important;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.item {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 13px 10px;
  border: none;
  background-color: #656a81;
  color: #d8d9e4;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    border-color: #54586c;
    background: #54586c;
    color: #ff7a00;
  }
}
