.root {
  display: flex;
  width: 84px;
  align-items: center;
  justify-content: space-between;

  button {
    width: 24px;
    height: 24px;
    padding: 0;
  }
}
