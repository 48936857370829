.breadcrumb {
  padding-left: 0;
  margin: 0;
  background-color: transparent;

  &-item.active {
    color: #54586c;
  }

  &-item + .breadcrumb-item::before {
    content: '>';
  }
}
