$font-sizes: 12, 18, 24;

/* stylelint-disable-next-line at-rule-no-unknown */
@each $font-size in $font-sizes {
  .fs-#{$font-size} {
    font-size: #{$font-size}px;
  }
}

.font-roboto {
  font-family: $font-roboto;
}

.text-link {
  color: $color-link;
}

.cursor-pointer {
  cursor: pointer;
}
