.root {
  background: #f1f3f8;
  grid-area: footer;
}

.backgroundDark {
  background: #eaedf5;
}

.content {
  max-width: 1440px;
  padding: 0 15px;
  margin: 0 auto;
}

.top {
  display: flex;
  padding: 20px 0;
}

.scrollTop {
  display: flex;
  height: 20px;
  align-items: center;
  margin-left: auto;
  color: #3a405e;
  cursor: pointer;

  & > svg {
    margin-right: 8px;
    color: #3a405e;
  }
}

.middle {
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  border-bottom: 1px solid #dee2ee;

  a {
    color: #3a405e;
  }
}

.icons {
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  align-items: center;
  margin-right: 120px;

  & > img {
    height: 23px;
    margin-right: 8px;
  }
}

.copyright {
  padding: 25px 0 35px;
  color: #3a405e;
  font-size: 16px;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .icon {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .icons {
    flex-direction: column;
    align-items: flex-start;
  }

  .icon {
    margin-bottom: 4px;
  }
}
