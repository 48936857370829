.root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;

  button {
    padding: 0 8px;
    border: none;
    background: none;
    color: #3a405e;

    &:hover {
      cursor: pointer;
    }
  }
}

.arrow {
  margin-bottom: 3px;
  font-size: 24px;
}

.number {
  font-size: 16px;
}

.active {
  font-weight: 700;
}

.ellipsis {
  padding: 0 8px;
  color: #3a405e;
  font-size: 16px;
}
