.tabs {
  border-bottom: 1px solid #e8ebf4;
}

.tab {
  display: inline-block;
  padding: 6px 15px;
  font-weight: 500;

  &:hover {
    color: #ff7a00;
    cursor: pointer;
  }

  &Selected {
    border-bottom: 2px solid #ff7a00;
    color: #ff7a00;
  }
}

.radios {
  overflow: auto;
  height: 200px;
  padding: 0 15px;
}

.radio {
  margin-top: 15px;
}
