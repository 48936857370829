.root {
  border: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.content {
  display: flex;
}

.avatar {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 50%;
}

.author {
  margin-right: 6px;
  color: #54586c;
  font-size: 14px;
  font-weight: 500;
}

.date {
  color: #a4a4b7;
  font-size: 12px;
}

.text {
  margin-bottom: 0;
  color: #6b6e7d;
  font-size: 12px;
}
