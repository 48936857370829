.root {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content {
  overflow: visible;
  width: 0;
  height: 0;
}
