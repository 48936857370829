.root {
  position: relative;
  display: inline-block;
  min-width: 16px;
  min-height: 16px;
  user-select: none;

  &:hover {
    &.input ~ .checkmark {
      background-color: #ccc;
    }
  }
}

.input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;

  &:checked ~ .checkmark {
    &::after {
      display: block;
    }
  }
}

.checkmark {
  position: absolute;
  width: 16px;
  height: 16px;
  border: 1px solid #cacdd9;
  border-radius: 2px;

  &::after {
    position: absolute;
    top: 2px;
    left: 2px;
    display: none;
    width: 10px;
    height: 10px;
    background: linear-gradient(0deg, #ff7a00, #ff7a00), linear-gradient(0deg, #ff7a00, #ff7a00),
      #ff7a00;
    border-radius: 2px;
    content: '';
  }
}
