.btn {
  &-light {
    background: transparent;
    color: $body-color;

    &.btn-rounded {
      display: flex;
      align-items: center;
      padding: 5px 15px 5px 5px;
      background-color: #f2f2f2;
      color: $primary-color;

      & > svg {
        display: inline-flex;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        padding: 8px;
        background: $primary-color;
        border-radius: 50%;
        color: #fff;
      }

      &:hover {
        background-color: #e2e6ea;
      }

      &:not(:disabled):not(.disabled):active {
        color: $primary-color;
      }
    }
  }

  &-outline-primary {
    border: none;
  }

  &-link-primary {
    color: $primary-color;

    &:hover {
      color: #d96800;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  &-link-light {
    color: #54586c;

    &:hover {
      color: #212529;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  &-rounded {
    padding: 10px;
    border-radius: 39px;
  }

  & > svg {
    margin-right: 8px;
  }

  .spinner-border {
    display: inline-block;
    margin-right: 8px;
  }
}
