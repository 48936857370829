.root {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  h2 {
    margin-bottom: 0;
  }

  > svg {
    margin-left: 8px;
  }
}
