$primary-color: #ff7a00;
$secondary-color: #a4a4b7;
$danger-color: #f1504c;
$theme-colors: (
  'primary': $primary-color,
  'secondary': $secondary-color,
  'danger': $danger-color,
);
$body-color: #54586c;
$font-family-sans-serif: -apple-system, blinkmacsystemfont, 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$font-size-base: 0.875rem;
$font-weight-bold: 500;
$btn-font-family: 'Montserrat', $font-family-sans-serif;
$btn-font-weight: 600;
$btn-line-height: 17px;
$btn-border-radius: 4px;
$btn-padding-x: 20px;
$btn-padding-y: 10px;
$link-color: #4a97d2;
$link-decoration: none;
$link-hover-decoration: none;
$input-placeholder-color: #acafbf;
$popover-border-radius: 4px;
$popover-border-width: 0;
$popover-arrow-width: 0;
$tooltip-bg: #fff;
$tooltip-color: $body-color;
