.root {
  display: flex;
  flex: 1;
  align-items: center;

  & > svg {
    margin-right: 12px;
  }

  & > select {
    cursor: pointer;

    &:focus,
    &:active {
      border: 1px solid #ced4da;
      box-shadow: none;
      cursor: pointer;
      outline: none;
    }
  }
}

.default,
%default {
  input {
    padding-left: 24px;
  }

  span {
    margin-left: 12px;
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 8px;
    display: block;
    width: 8px;
    height: 8px;
    background-color: #66af37;
    border-radius: 4px;
    content: '';
    transform: translateY(-50%);
  }
}

.increasedDemand {
  @extend %default;

  &::after {
    background-color: #e82938;
  }
}

.badWeather {
  @extend %default;

  &::after {
    background-color: #65e3eb;
  }
}
