* {
  outline: none;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;

    &-thumb {
      background: #a4a4b7;
    }

    &-track {
      background: transparent;
    }
  }
}

html {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

body {
  position: fixed;
  overflow: hidden;
  width: 100%;
  min-width: 320px;
  height: 100%;
  margin: 0;
  font-family: $font-roboto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input,
textarea {
  &::placeholder {
    color: #acafbf;
    font-size: 12px;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  appearance: none;
}

#root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.main-container {
  display: grid;
  width: 100%;
  min-height: calc(100% + 218px);
  grid-template-areas:
    'drawer header'
    'drawer main'
    'footer footer';
  grid-template-columns: min-content 1fr;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  grid-template-rows: 64px 1fr;

  @media only screen and (max-width: 768px) {
    grid-template-rows: 48px 1fr;
  }

  @media only screen and (max-width: 576px) {
    min-height: calc(100% + 276px);
  }
}

.main {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: #f9fafc;
  grid-area: main;
  overflow-x: auto;
  overflow-y: hidden;

  & > .container {
    padding: 0;

    &-fluid {
      padding: 0;
    }
  }
}

.row-red-light {
  background-color: #fde2df !important;

  td {
    background-color: #fde2df !important;
  }

  &:hover {
    background-color: #ffefe7 !important;

    td {
      background-color: #ffefe7 !important;
    }
  }
}

.row-green-light {
  background-color: #d2ee94 !important;

  td {
    background-color: #d2ee94 !important;
  }

  &:hover {
    background-color: #d2ee94 !important;

    td {
      background-color: #d2ee94 !important;
    }
  }
}

.row-blue-light {
  background-color: #a2eef3 !important;

  td {
    background-color: #a2eef3 !important;
  }

  &:hover {
    background-color: #a2eef3 !important;

    td {
      background-color: #a2eef3 !important;
    }
  }
}
