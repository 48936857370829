@import '~@fontsource/roboto/400.css';
@import '~@fontsource/roboto/500.css';
@import '~@fontsource/roboto/700.css';
@import '~@fontsource/montserrat/500.css';
@import '~@fontsource/montserrat/600.css';
@import '~@fontsource/source-sans-pro/600.css';

@import './variables';

@import './bootstrap/index';
@import './react-calendar';
@import './react-phone-input';
@import './react-toastify';

@import './utilities';
@import './global';
