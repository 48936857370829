$rt-toast-width: 255px;
$rt-color-success: #76c643;
$rt-color-error: #e82938;
$rt-font-family: 'Roboto', sans-serif;

@import 'react-toastify/scss/main';

.Toastify__toast {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  color: #54586c;
  font-size: 14px;

  img {
    margin-right: 8px;
  }

  &--success {
    border-top: 8px solid $rt-color-success;
  }

  &--error {
    border-top: 8px solid $rt-color-error;
  }
}
