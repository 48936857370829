.root {
  width: 80px;
  border-right: 1px solid #edeff7;
  grid-area: drawer;

  &Opened {
    width: 275px;
  }
}

.logo {
  height: 52px;
  margin: 10px 20px;
}

.selectWrapper {
  display: flex;
  height: 90px;
  align-items: center;
  padding: 0 28px;
  font-size: 16px;
}

.item {
  position: relative;
  display: flex;
  height: 42px;
  align-items: center;
  padding: 0 30px;
  color: #54586c;
  font-size: 16px;

  &:last-child {
    margin-top: 64px;
  }

  &:hover,
  &Active {
    color: #ff7a00;
    cursor: pointer;
    text-decoration: none;

    .itemIcon {
      color: #ff7a00;
    }
  }

  &Active {
    .itemMarker {
      display: block;
    }
  }

  &Icon {
    margin-right: 15px;
    color: #a4a4b7;
  }

  &Marker {
    position: absolute;
    top: 6px;
    left: 0;
    display: none;
    width: 5px;
    height: 30px;
    background-color: #ff7a00;
  }

  &Options {
    height: 21px;
    padding-left: 80px;
    margin-bottom: 8px;
    list-style: none;

    li {
      &:hover,
      &.active {
        cursor: pointer;

        &::marker {
          color: #ff7a00;
        }

        a {
          color: #ff7a00;
        }
      }

      a {
        color: #54586c;
      }
    }
  }
}

.divider {
  height: 1px;
  margin: 15px 30px;
  background-color: #edeff7;
}

@media only screen and (max-width: 768px) {
  .root {
    position: absolute;
    z-index: 80;
    width: 275px;
    height: 100%;
    background: #fff;
    transform: translateX(-275px);
    transition: all 0.5s;

    &Opened {
      transform: translateX(0);
    }
  }

  .logo {
    height: 36px;
    margin: 6px 12px;
  }

  .selectWrapper {
    height: 64px;
    padding: 0 14px;
  }

  .item {
    padding: 0 15px;

    &DesktopOnly {
      display: none;
    }
  }

  .divider {
    display: none;
  }
}
