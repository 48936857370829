.root {
  padding: 4px 0;
  margin: 0;
}

.empty {
  display: block;
  padding: 8px;
  color: #acafbf;
  text-align: center;
}

.groupLabel {
  padding: 4px 12px;
  font-family: 'Source Sans Pro', Roboto, sans-serif;
  font-weight: 600;
  line-height: 16px;
  user-select: none;
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid transparent;

  &:hover {
    background-color: rgba(255, 122, 0, 0.25);
  }

  &Label {
    color: #54586c;
  }

  &Checkbox {
    margin-right: 8px;
  }

  &Selected {
    background-color: #ff7a00;

    &:hover {
      background-color: #ff7a00;
    }

    .itemLabel {
      color: #fff;
    }
  }
}
