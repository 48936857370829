.root {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  color: #3a405e;
  font-size: 16px;
  line-height: 18px;

  & > span {
    margin-right: 16px;
    margin-left: 7px;
    color: #3a405e;
    font-size: 18px;
    font-weight: bold;
  }

  & > select {
    width: 70px;
    height: 30px;
    padding: 5px 8px;
    border: 1px solid #cacdd9;
    margin-right: 11px;
    border-radius: 4px;
    color: #3a405e;
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
  }

  :global {
    .pagination {
      margin-bottom: 0;

      .page-item {
        .page-link {
          width: 26px;
          height: 26px;
          padding: 0 8px;
          border: 1px solid #cacdd9;
          margin-bottom: 0;
          background-color: #fff;
          font-size: 24px;
          line-height: 22px;

          span {
            margin-bottom: 2px;
            color: #bcbcd0;
          }
        }
      }
    }
  }
}
