.root {
  position: relative;

  & > svg {
    position: absolute;
    z-index: 1;

    &:first-child {
      top: 16px;
      left: 19px;
    }

    &:last-child {
      top: 18px;
      right: 19px;
    }
  }

  & > input {
    overflow: hidden;
    width: 100%;
    padding: 16px 48px;
    border: none;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 3px 10px rgba(7, 9, 27, 0.1);
    line-height: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::placeholder {
      font-size: 14px;
    }
  }
}
