@import 'react-calendar/dist/Calendar.css';

.react-calendar {
  border: 1px solid #d2d2db;
  border-radius: 4px;
  font-family: $font-roboto;

  &__navigation {
    height: 36px;
    margin-bottom: 8px;

    &__arrow,
    &__label {
      border-radius: 4px;
    }

    &__arrow > svg {
      font-size: 16px;
    }

    &__next2-button,
    &__prev2-button {
      display: none;
    }
  }

  &__month-view__weekdays {
    abbr[title] {
      text-decoration: none;
    }
  }

  &__tile {
    border-radius: 4px;

    &--active,
    &--hasActive {
      background: $color-primary;
      color: $color-white;

      &:enabled:hover,
      &:enabled:focus {
        background: $color-primary--hover;
      }
    }
  }
}
