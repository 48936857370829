.root {
  position: sticky;
  z-index: 50;
  top: 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #edeff7;
  background-color: #fff;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.07);
}

.row {
  display: flex;
  height: 50px;
  background-color: #fff;

  th {
    background-color: #fff;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #edeff7;
  }
}
