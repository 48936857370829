.root {
  display: flex;
  background-color: #fff;

  td {
    background-color: #fff;
  }

  &:nth-of-type(odd) {
    background-color: #f8f8f8;

    td {
      background-color: #f8f8f8;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid #edeff7;
  }

  &:hover {
    background-color: #ffefe7;

    td {
      background-color: #ffefe7;
    }
  }
}
